import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Button from "../components/Button";
import Ctas from "../components/Ctas";
import Headline from "../components/Headline";
import { Github } from "../components/Icons";
import Layout from "../components/Layout";
import Section from "../components/Section";
import ToolkitItem from "../components/ToolkitItem";
import features from "../lib/features";
import Contributing from "../components/Contributing";
import FooterCta from "../components/FooterCta";
import SqueakSnippet from "../components/SqueakSnippet";

const Index = () => {
  return (
    <Layout title="Build a community around your product" description="Squeak! is a bespoke toolkit for fostering a community in-house. It helps make discussions, resources, and solutions more accessible to the right people.

    ">
      <Section id="hero">
        <div className="py-6 md:pt-12 md:pb-6">
          <StaticImage
            className="w-[calc(100%-350px] py-8 -mx-4 md:py-8 md:-mx-8 lg:py-0 lg:mx-0 lg:w-[779px] lg:max-w-2xl xl:max-w-3xl 2xl:max-w-4xl 2xl:w-full transition-all duration-300"
            src="../images/squeak-hogs.png"
            placeholder="none"
            alt="Just some chatty hedgehogs"
          />
        </div>
        <header className="max-w-5xl py-4">
          <Headline
            h1="Build a community around your product"
            subtitle="Squeak! is a bespoke toolkit for fostering a community in-house. It
            helps make discussions, resources, and solutions more accessible to
            the right people."
          />

          <Ctas>
            <Button url="https://squeak.cloud/signup">Try Squeak! Cloud</Button>

            <div className="relative">
              <Button
                url="https://github.com/posthog/squeak"
                type="secondary"
                icon={<Github />}
                className="w-full md:w-auto"
              >
                Browse on GitHub
              </Button>
              {/* <TrySqueak className="sm:block hidden absolute top-[105%] pt-1 left-[75%] -rotate-6" /> */}
            </div>
          </Ctas>

          <p className="text-base">
            Squeak! is{" "}
            <Link
              href="https://github.com/posthog/squeak"
              target="_blank"
              rel="noreferrer"
              className=""
            >
              open source
            </Link>{" "}
            and maintained by{" "}
            <Link
              href="https://posthog.com"
              target="_blank"
              rel="noreferrer"
              className=""
            >
              PostHog
            </Link>
            .
          </p>
        </header>
      </Section>

      <Section id="toolkit">
        <h2>Explore the toolkit</h2>
        <p className="text-base">
          Squeak! tools are built for React-based static sites, but many
          products work with existing docs, knowledge bases, or websites.
        </p>

        <ul className="max-w-[800px] my-4 -mx-6">
          {features.map((feature) => {
            return (
              <li className="space-y-3 md:mb-[1px]">
                <ToolkitItem {...feature} />
              </li>
            );
          })}
        </ul>
      </Section>

      <Contributing />

      <FooterCta
        title="Well if you've made it this far..."
        description="Have you considered just giving it a try? It's free."
      />
    </Layout>
  );
};

export default Index;
